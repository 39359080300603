var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header-title" }, [
          _c("img", { attrs: { src: "/images/favicon-32x32.png" } }),
          _vm._v("\n       \n      "),
          _c("h4", { staticClass: "title is-4" }, [
            _vm._v("\n        What can I use this wheel spinner for?\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n      Every day we hear from people who use our website in new ways:\n      "
          ),
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n          Random name picker in the classroom: pick which student will answer\n          the next question.\n        "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n          If you are a retailer, spin the wheel to pick which loyal customer\n          will get the monthly giveaway.\n        "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n          When you give a presentation, use the wheel spinner to pick a lucky\n          winner among the attendees who turned in the survey.\n        "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n          Random name picker at work: in your daily standup meeting at work,\n          randomize who speaks first.\n        "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n          If you are overwhelmed by your to do items, put them on a wheel and\n          spin to find which one to start with.\n        "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n          If you can't agree on what to have for dinner, put the alternatives\n          on the wheel and spin.\n        "
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }