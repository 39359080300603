var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.twitterDialogVisible, width: 640, scroll: "keep" },
      on: {
        "update:active": function($event) {
          _vm.twitterDialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _c("i", { staticClass: "fab fa-twitter" }),
            _vm._v(
              " " + _vm._s(_vm.$t("common.Import Twitter users")) + "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "modal-card-body can-go-dark" },
          [
            _c(
              "b-field",
              [
                _c("b-input", {
                  ref: "searchTermField",
                  attrs: {
                    placeholder: _vm.$t("twitterdialog.Hashtag, like #gdg")
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getTwitterUsers($event)
                    }
                  },
                  model: {
                    value: _vm.searchTerm,
                    callback: function($$v) {
                      _vm.searchTerm = $$v
                    },
                    expression: "searchTerm"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticStyle: { color: "#999" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("twitterdialog.This search will fetch")
                )
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass: "modal-card-foot",
            staticStyle: { "justify-content": "flex-end" }
          },
          [
            _c(
              "b-button",
              {
                attrs: { size: "is-medium" },
                on: {
                  click: function($event) {
                    _vm.twitterDialogVisible = false
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.Cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: {
                  size: "is-medium",
                  type: "is-primary",
                  disabled: _vm.searchTerm == ""
                },
                on: { click: _vm.getTwitterUsers }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("common.OK")) + "\n      ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }