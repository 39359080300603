var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      !_vm.wheelIsShared
        ? _c(
            "div",
            { style: _vm.$mq == "desktop" ? "height: 100%" : "" },
            [
              _c("toolbar", {
                on: {
                  "show-snackbar-message": _vm.showSnackbarMessage,
                  "reset-wheel": function($event) {
                    return _vm.resetWheel()
                  },
                  "open-open-dialog": function($event) {
                    return _vm.openOpenDialog()
                  },
                  "open-save-dialog": function($event) {
                    return _vm.openSaveDialog()
                  },
                  "open-share-dialog": function($event) {
                    return _vm.openShareDialog()
                  },
                  "open-customize-dialog": function($event) {
                    return _vm.openCustomizeDialog()
                  },
                  "open-twitter-dialog": function($event) {
                    return _vm.openTwitterDialog()
                  },
                  "open-sheet-dialog": function($event) {
                    return _vm.openSheetDialog()
                  },
                  "open-account-dialog": function($event) {
                    return _vm.openAccountDialog()
                  },
                  "set-locale": _vm.setLocale
                }
              }),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticClass: "section",
                  staticStyle: {
                    height: "100%",
                    "padding-top": "1rem",
                    "padding-bottom": "0"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "columns", staticStyle: { height: "100%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-3" },
                        [
                          _c("titleAndDescription", {
                            attrs: { editable: !_vm.fullScreen },
                            on: {
                              editTitleAndDescription: function($event) {
                                return _vm.openTitleAndDescriptionDialog()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "column is-6",
                          class: { "is-7": _vm.fullScreen }
                        },
                        [
                          _c("spinningwheel", {
                            ref: "spinningwheel",
                            on: {
                              "wheel-started": _vm.wheelStarted,
                              "name-changed": _vm.nameChanged,
                              "wheel-stopped": _vm.wheelStopped
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "column is-3",
                          staticStyle: { height: "90%" }
                        },
                        [
                          _c("name-tabs", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fullScreen,
                                expression: "!fullScreen"
                              }
                            ],
                            staticStyle: { height: "100%" },
                            on: {
                              "reset-wheel-rotation": _vm.resetWheelRotation
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.wheelIsShared
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "section",
                staticStyle: { "padding-top": "1rem", "padding-left": "1rem" }
              },
              [
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "column is-3",
                      staticStyle: { "font-family": "Quicksand" }
                    },
                    [
                      _vm.wheelTitle
                        ? _c("h3", { staticClass: "subtitle is-3" }, [
                            _vm._v(_vm._s(_vm.wheelTitle))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.wheelDescription
                        ? _c("p", [_vm._v(_vm._s(_vm.wheelDescription))])
                        : _vm._e(),
                      _c("br"),
                      _vm._v(" "),
                      !_vm.wheelIsCopyable
                        ? _c(
                            "b-button",
                            {
                              attrs: { disabled: _vm.wheelIsBusy },
                              on: { click: _vm.createYourOwn }
                            },
                            [
                              _c("i", { staticClass: "fas fa-pencil-alt" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("app.Create your own")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.wheelIsCopyable
                        ? _c(
                            "b-button",
                            {
                              attrs: { disabled: _vm.wheelIsBusy },
                              on: { click: _vm.copyWheel }
                            },
                            [
                              _c("i", { staticClass: "far fa-clone" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("app.Copy this wheel")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("muteToggle")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-7" },
                    [
                      _c("spinningwheel", {
                        ref: "spinningwheel",
                        on: {
                          "wheel-started": _vm.wheelStarted,
                          "name-changed": _vm.nameChanged,
                          "wheel-stopped": _vm.wheelStopped
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      !_vm.fullScreen ? _c("aboutCards") : _vm._e(),
      _vm._v(" "),
      _c("opendialog", {
        ref: "opendialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError,
          "reset-wheel-rotation": _vm.resetWheelRotation,
          "reset-address-bar": _vm.resetAddressBar
        }
      }),
      _vm._v(" "),
      _c("savedialog", {
        ref: "savedialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError,
          "reset-address-bar": _vm.resetAddressBar
        }
      }),
      _vm._v(" "),
      _c("optionsdialog", {
        ref: "optionsdialog",
        on: { "show-snackbar-message": _vm.showSnackbarMessage }
      }),
      _vm._v(" "),
      _c("sharedialog", {
        ref: "sharedialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError
        }
      }),
      _vm._v(" "),
      _c("twitterdialog", {
        ref: "twitterdialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation
        }
      }),
      _vm._v(" "),
      _c("sheetdialog", {
        ref: "sheetdialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "auth-error": _vm.authError
        }
      }),
      _vm._v(" "),
      _c("accountdialog", {
        ref: "accountdialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError
        }
      }),
      _vm._v(" "),
      _c("winnerdialog", {
        ref: "winnerdialog",
        on: {
          "remove-entry": _vm.removeEntry,
          "remove-entry-all": _vm.removeEntryAll,
          "hide-entry": _vm.hideEntry
        }
      }),
      _vm._v(" "),
      _c("titleAndDescriptionDialog", {
        ref: "titleAndDescriptionDialog",
        on: { setTitleAndDescription: _vm.setTitleAndDescription }
      }),
      _vm._v(" "),
      _c("winneranimation", { ref: "winneranimation" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }