var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticStyle: { "box-shadow": "0 3px 3px 0 lightgrey" },
      attrs: { type: "is-info" }
    },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          _c(
            "b-navbar-item",
            { staticStyle: { "font-size": "24px" }, attrs: { href: "/" } },
            [_vm._v("\n      " + _vm._s(_vm.toolbarBrand) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "start" },
        [
          _c(
            "b-tag",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.online,
                  expression: "!online"
                }
              ],
              staticStyle: { "margin-top": "1.2em" },
              attrs: { type: "is-warning" }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("toolbar.Offline mode")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "end" },
        [
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newButtonVisible,
                  expression: "newButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$emit("reset-wheel")
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-file fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.New")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openButtonVisible,
                  expression: "openButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$emit("open-open-dialog")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-folder-open fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("common.Open")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.saveButtonVisible,
                  expression: "saveButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$emit("open-save-dialog")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-save fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("common.Save")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shareButtonVisible,
                  expression: "shareButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$emit("open-share-dialog")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-share-alt fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Share")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.optionsButtonVisible,
                  expression: "optionsButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$emit("open-customize-dialog")
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-palette fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("common.Customize")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fullscreenButtonVisible,
                  expression: "fullscreenButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.enterFullScreen()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-expand fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Fullscreen")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.exitFullscreenButtonVisible,
                  expression: "exitFullscreenButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.exitFullScreen()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-compress fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Exit fullscreen")) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unlinkSheetButtonVisible,
                  expression: "unlinkSheetButtonVisible"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.$store.commit("unlinkSheet")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-unlink fa-fw" }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("toolbar.Unlink Google Spreadsheet")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.browserIsIEOrOldEdge
            ? [
                _c(
                  "b-navbar-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.feedbackbuttonVisible,
                        expression: "feedbackbuttonVisible"
                      }
                    ],
                    on: { click: _vm.openFeedbackForm }
                  },
                  [
                    _c("i", { staticClass: "fa fa-comment fa-fw" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("toolbar.Feedback")) + "\n      "
                    )
                  ]
                )
              ]
            : [
                _c(
                  "b-navbar-dropdown",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.moreVisible,
                        expression: "moreVisible"
                      }
                    ],
                    attrs: { label: _vm.$t("toolbar.More"), right: true }
                  },
                  [
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.feedbackbuttonVisible,
                            expression: "feedbackbuttonVisible"
                          }
                        ],
                        on: { click: _vm.openFeedbackForm }
                      },
                      [
                        _c("i", { staticClass: "fa fa-comment fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("toolbar.Feedback")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.faqbuttonVisible,
                            expression: "faqbuttonVisible"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/faq")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-question-circle fa-fw" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("toolbar.FAQ")) + "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.feedbackbuttonVisible,
                            expression: "feedbackbuttonVisible"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/privacy-policy")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-user-secret fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common.Privacy policy")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.feedbackbuttonVisible,
                            expression: "feedbackbuttonVisible"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/faq/terms")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-balance-scale fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("appInfo.Terms of service")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-navbar-item"),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.importVisible,
                            expression: "importVisible"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$emit("open-twitter-dialog")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fab fa-twitter fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common.Import Twitter users")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.importVisible,
                            expression: "importVisible"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$emit("open-sheet-dialog")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-link fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common.Link Google Spreadsheet")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.browserIsIEOrOldEdge
                      ? _c(
                          "b-navbar-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.optionsButtonVisible,
                                expression: "optionsButtonVisible"
                              }
                            ],
                            on: {
                              click: function($event) {
                                return _vm.toggleDarkMode()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-moon fa-fw" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("toolbar.Dark mode")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("b-navbar-item"),
                    _vm._v(" "),
                    _c(
                      "b-navbar-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$emit("open-account-dialog")
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-user-alt fa-fw" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common.My account")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.$mq == "mobile"
                  ? _c(
                      "b-navbar-dropdown",
                      { attrs: { label: _vm.$t("toolbar.Language") } },
                      _vm._l(_vm.locales, function(locale) {
                        return _c(
                          "b-navbar-item",
                          {
                            key: locale.name,
                            on: {
                              click: function($event) {
                                return _vm.$emit("set-locale", locale.name)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(locale.humanName) +
                                "\n      "
                            )
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$mq != "mobile"
                  ? _c(
                      "b-navbar-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.languageVisible,
                            expression: "languageVisible"
                          }
                        ],
                        attrs: { tag: "div", href: "#" }
                      },
                      [
                        _c(
                          "b-select",
                          {
                            model: {
                              value: _vm.locale,
                              callback: function($$v) {
                                _vm.locale = $$v
                              },
                              expression: "locale"
                            }
                          },
                          [
                            _vm._l(_vm.locales, function(locale) {
                              return _c(
                                "option",
                                {
                                  key: locale.name,
                                  domProps: { value: locale.name }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(locale.humanName) +
                                      "\n        "
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "add-your-language" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t("toolbar.Add your language")
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }